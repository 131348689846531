<template>
  <div class="space">
    <Loader :loading="showLoader" />
    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title class="custom-toolbar-title">
        <!-- Align "Functions" title next to the logo -->
        {{ 'Work Space' }}
      </v-toolbar-title>

      <v-btn icon @click="openMapView">
        <v-icon :color="primaryColor">mdi-map</v-icon>
      </v-btn>
      <v-btn icon @click="openDialog">
        <v-icon :color="primaryColor">mdi-home-city-outline</v-icon>
      </v-btn>
      <v-btn icon @click="showSearch = !showSearch">
        <v-icon :color="primaryColor">mdi-magnify</v-icon>
      </v-btn>
      <v-fade-transition>
        <v-text-field v-if="showSearch" v-model="search" label="Search" single-line hide-details class="search-field" dense></v-text-field>
      </v-fade-transition>
    </v-toolbar>

    <v-dialog v-model="showDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>Select Building</v-card-title>
        <v-card-text>
          <v-select
            :items="buildings"
            item-value="name"
            item-title="name"
            label="Choose a building"
            v-model="tempSelectedBuilding"
            return-object
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirmSelection">Confirm</v-btn>
          <v-btn color="red darken-1" text @click="cancelSelection">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container>
      <div class="subtitle-2" style="margin-top: -1.5rem" v-if="selectedBuilding">
        <v-chip prepend-icon="mdi-office-building" class="custom-chip ma-2" color="dark" label>
          {{ selectedBuilding }}
        </v-chip>
      </div>
      <v-row dense>
        <v-col v-for="poiCategory in poiCategories" :key="poiCategory.id" cols="12" sm="6" md="4" lg="3" class="mb-4 mt-3">
          <v-card @click="goToPoiCategory(poiCategory)" class="elegant-card">
            <div class="card-image-container">
              <img :src="poiCategory.image" class="card-image" />
              <div class="text-overlay">
                <div class="text-h6">{{ poiCategory.name }}</div>
                <div class="subtitle-1">
                  Available Rooms:
                  <strong>{{ poiCategory.pois?.length }}</strong>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <MapView ref="mapView" />
  </div>
</template>

<script>
  import { defineComponent, ref, onMounted, computed, watch } from 'vue'
  import { debounce } from 'lodash'
  import { Preferences } from '@capacitor/preferences'
  import { Device } from '@capacitor/device'
  import { getStoredItem, setStoredItem } from '@/services/utils.js'
  import { getPrimaryColor, getToolbarLogoImage, getToolBarCustomerName } from '@/assets/Branding/branding.js'
  import lodash from 'lodash'
  import { getBuildings, getPoiCategoriesByBuildingId, getFile, getDataFromBlob, getAllFiles } from '@/controllers/BaseController'
  import Loader from '@/components/general/Loader.vue'
  import MapView from '@/components/general/MapView.vue'

  import GoogleMapsView from '@/components/general/GoogleMapsView.vue'
  import { useRouter } from 'vue-router'

  export default defineComponent({
    name: 'BuildingView',
    components: {
      Loader,
      MapView,
    },
    setup() {
      const showDialog = ref(false)
      const showSearch = ref(false)
      const search = ref('')
      const logo = ref('')
      const primaryColor = ref('')
      const customerName = ref('')
      const showLoader = ref(true)
      const buildings = ref([])
      const selectedBuilding = ref(null)
      const tempSelectedBuilding = ref(null)
      const poiCategories = ref([])
      const mapView = ref(null)
      const router = useRouter()

      onMounted(async () => {
        try {
          showLoader.value = true
          const [toolbarLogo, customer, apiBuildings] = await Promise.all([getToolbarLogoImage(), getToolBarCustomerName(), getBuildings()])

          logo.value = toolbarLogo
          customerName.value = customer
          buildings.value = apiBuildings
          primaryColor.value = await getPrimaryColor()

          const storedBuilding = await getStoredItem('selectedBuilding')
          selectedBuilding.value = storedBuilding || apiBuildings[0]?.name || null

          if (!storedBuilding) {
            await setStoredItem('selectedBuilding', selectedBuilding.value)
          }

          let selectedBuildingId = apiBuildings.find(building => building.name === selectedBuilding.value)?.id

          let categories = await getPoiCategoriesByBuildingId(selectedBuildingId)

          await Promise.all(
            categories.map(async category => {
              let image = lodash.some(category.files.entities, {
                type: 'image',
              })
              if (image) {
                const imageId = category.files.entities.find(file => file.type === 'image').id
                const blob = await getFile(imageId)
                const imageDataUrl = await getDataFromBlob(blob)
                category.image = imageDataUrl
              } else {
                category.image = require('@/assets/CategoryImages/teamzone_iq.jpg')
              }
            })
          )

          poiCategories.value = categories
          showLoader.value = false
        } catch (error) {
          console.error('Error fetching buildings:', error)
        }
      })

      const filteredBuildings = computed(() => {
        return buildings.value.filter(building => building.name.toLowerCase().includes(search.value.toLowerCase()))
      })

      watch(
        search,
        debounce(() => {
          console.log('Search debounced:', search.value)
        }, 300)
      )

      function toggleSearch() {
        showSearch.value = !showSearch.value
      }

      function confirmSelection() {
        let building = JSON.parse(JSON.stringify(tempSelectedBuilding.value))
        building = building.name
        selectedBuilding.value = building
        setStoredItem('selectedBuilding', building)
        showDialog.value = false
        window.location.reload()
      }

      function cancelSelection() {
        tempSelectedBuilding.value = selectedBuilding.value
        showDialog.value = false
      }

      function goToPoiCategory(poiCategory) {
        let category = JSON.parse(JSON.stringify(poiCategory))
        let buildingSelected = buildings.value.find(building => building.name === selectedBuilding.value)
        buildingSelected = JSON.parse(JSON.stringify(buildingSelected))
        router.push({
          name: 'PoisListView',
          params: {
            building_id: buildingSelected.id,
            category_id: category.id,
          },
        })
      }

      function openDialog() {
        tempSelectedBuilding.value = selectedBuilding.value
        showDialog.value = true
      }

      function openMapView() {
        let SELECTED_BUILDING = buildings.value.find(building => building.name === selectedBuilding.value)
        SELECTED_BUILDING = JSON.parse(JSON.stringify(SELECTED_BUILDING))
        let POI_CATEGORIES = JSON.parse(JSON.stringify(poiCategories.value))
        let POIS = []
        POI_CATEGORIES.forEach(category => {
          POIS.push(category.pois)
        })
        POIS = lodash.flatten(POIS)
        mapView.value.openMap(SELECTED_BUILDING, POIS, POI_CATEGORIES)
      }

      return {
        showDialog,
        showSearch,
        search,
        logo,
        customerName,
        buildings: filteredBuildings,
        selectedBuilding,
        tempSelectedBuilding,
        showLoader,
        poiCategories,
        toggleSearch,
        confirmSelection,
        goToPoiCategory,
        cancelSelection,
        openDialog,
        openMapView,
        mapView,
        primaryColor,
      }
    },
  })
</script>

<style scoped>
  .space {
    padding: 1rem;
  }

  .custom-toolbar {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    color: #333;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
  }

  .custom-toolbar-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #333;
    margin-left: 10px;
    /* Spacing between logo and title */
  }

  .toolbar-icon {
    height: 30px;
  }

  .custom-toolbar-btn {
    color: #007bff;
    /* Primary color for icons */
    margin-right: 8px;
    transition: color 0.3s ease;
  }

  .custom-toolbar-btn:hover {
    color: #0056b3;
    /* Darker shade on hover */
  }

  /* Card Enhancement */
  .elegant-card {
    background-color: #ffffff;
    /* Light background for contrast */
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    height: 220px;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    will-change: transform, box-shadow;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Subtle initial shadow */
  }

  .elegant-card:hover {
    transform: translateY(-5px);
    /* Slight lift on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    /* Darker shadow on hover */
  }

  .card-image-container {
    position: relative;
    height: 100%;
  }

  .card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    opacity: 0.85;
    /* Initial opacity */
  }

  .elegant-card:hover .card-image {
    opacity: 1;
    /* Full opacity on hover */
    transform: scale(1.05);
    /* Subtle zoom effect */
  }

  .text-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent dark overlay */
    color: #ffffff;
    /* White text for contrast */
    padding: 12px;
    transition: background-color 0.3s ease-out;
    will-change: background-color;
    z-index: 1;
    /* Ensure the overlay is above the image */
  }

  .elegant-card:hover .text-overlay {
    background: rgba(0, 0, 0, 0.7);
    /* Darker overlay on hover */
  }

  .white--text {
    color: white;
  }

  /* Responsive Adjustments */
  @media (max-width: 600px) {
    .custom-toolbar {
      padding: 8px;
    }

    .custom-toolbar-title {
      font-size: 1.2rem;
      max-width: calc(100% - 88px);
      /* Adjust max-width to fit smaller screens */
    }

    .custom-toolbar-btn {
      margin-right: 4px;
    }
  }
</style>
