<template>
  <div class="home-view">
    <!-- Custom Toolbar with Weather and Clock -->
    <div class="custom-toolbar">
      <div class="toolbar-left">
        <h2>Hi, {{ userProfile.name }}</h2>
        <p>{{ isMobileView ? moment(date).format('ddd, DD MMM YYYY') : moment(date).format('ddd, DD MMMM YYYY') }}</p>
        <!-- Enhanced Weather Display -->
        <div class="weather-display">
          <div class="weather-info">
            <div class="weather-item">
              <v-icon class="weather-icon">mdi-thermometer</v-icon>
              <p class="weather-text">{{ weatherTemp }}°C - {{ weatherDesc }}</p>
            </div>
            <div class="weather-item" :style="isMobileView ? 'margin-top:-1rem' : ''">
              <v-icon class="weather-icon">mdi-water-percent</v-icon>
              <p class="weather-text">Humidity: {{ weatherHumidity }}%</p>
            </div>
            <div class="weather-item" :style="isMobileView ? 'margin-top:-1rem' : ''">
              <v-icon class="weather-icon">mdi-weather-windy</v-icon>
              <p class="weather-text">Wind Speed: {{ weatherWind }} m/s</p>
            </div>
            <div class="weather-item" :style="isMobileView ? 'margin-top:-1rem' : ''">
              <v-icon class="weather-icon">mdi-weather-rainy</v-icon>
              <p class="weather-text">Rain Chance: {{ weatherRain }}%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="toolbar-right" :style="isMobileView ? 'margin-top:-3rem' : ''">
        <AnalogClock />
      </div>
    </div>

    <!-- Animated button coming from the left -->
    <div class="animated-button-container">
      <AnimatedButton />
    </div>

    <!-- Grid with cards -->
    <div class="card-grid" :style="isMobileView ? 'margin-top:-0.1rem' : ''">
      <div class="card">
        <v-icon class="card-icon">mdi-desktop-mac</v-icon>
        <div class="info">
          <h3>Desks Available</h3>
          <p>{{ availableDesks }} desks free</p>
        </div>
      </div>
      <div class="card">
        <v-icon class="card-icon">mdi-account-multiple</v-icon>
        <div class="info">
          <h3>Colleagues Present</h3>
          <p>{{ colleaguesPresent }} present today</p>
        </div>
      </div>
      <div class="card">
        <v-icon class="card-icon">mdi-calendar-check</v-icon>
        <div class="info">
          <h3>Your Attendance</h3>
          <p>{{ attendanceCount }} days this month</p>
        </div>
      </div>
      <div class="card">
        <v-icon class="card-icon">mdi-flash</v-icon>
        <div class="info">
          <h3>Quick Action</h3>
          <p>Book a desk or chat</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AnalogClock from '@/components/general/ChildComponents/AnalogClock.vue'
  import AnimatedButton from '@/components/general/ChildComponents/AnimatedButton.vue'
  import { defineComponent, ref, onMounted } from 'vue'
  import { useOAuthService } from '@/services/OAuthService'
  import moment from 'moment'

  export default defineComponent({
    name: 'HomeView',
    components: { AnalogClock, AnimatedButton },
    setup() {
      const availableDesks = ref(0)
      const isMobileView = ref(window.innerWidth <= 960)
      const showCards = ref(false) // Control card animation
      const colleaguesPresent = ref(0)
      const attendanceCount = ref(0)
      const weatherTemp = ref(0)
      const weatherDesc = ref('')
      const weatherHumidity = ref(0)
      const weatherWind = ref(0)
      const weatherRain = ref(0)
      const userProfile = ref({ name: 'User' })
      const apiKey = '4ae793a3dd93a074ff16d7e97b06ec5b'
      const city = 'Munich'

      const buttons = ref([
        { icon: '🖥️', title: 'Desks Available', description: `${availableDesks.value} desks free` },
        { icon: '👥', title: 'Colleagues Present', description: `${colleaguesPresent.value} present today` },
        { icon: '📅', title: 'Your Attendance', description: `${attendanceCount.value} days this month` },
        { icon: '⚡', title: 'Quick Action', description: 'Book a desk or chat' },
      ])

      const handleResize = () => {
        isMobileView.value = window.innerWidth <= 960
      }

      window.addEventListener('resize', handleResize)

      async function fetchHomeData() {
        try {
          availableDesks.value = 5
          colleaguesPresent.value = 12
          attendanceCount.value = 8
        } catch (error) {
          console.log(error)
        }
      }

      onMounted(async () => {
        try {
          userProfile.value = await useOAuthService().getUserProfile()
          await fetchWeather()
          await fetchHomeData()
        } catch (error) {
          console.log(error)
          showCards.value = true
        } finally {
          showCards.value = true
        }
      })

      async function fetchWeather() {
        const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}&units=metric`)
        const data = await response.json()
        weatherTemp.value = data.main.temp.toFixed(1)
        weatherDesc.value = data.weather[0].description
        weatherHumidity.value = data.main.humidity
        weatherWind.value = data.wind.speed
        weatherRain.value = data.rain ? data.rain['1h'] : 0
      }

      return {
        availableDesks,
        colleaguesPresent,
        attendanceCount,
        weatherTemp,
        weatherDesc,
        weatherHumidity,
        weatherWind,
        weatherRain,
        userProfile,
        buttons,
        isMobileView,
        moment,
        showCards,
        fetchWeather,
      }
    },
  })
</script>

<style scoped>
  .home-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #eef2f6 0%, #ffffff 100%);
    padding: 20px;
  }

  /* Custom toolbar with improved layout */
  .custom-toolbar {
    background: linear-gradient(135deg, #ffffff 0%, #f5f5f5 100%);
    width: 60%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-bottom: 40px;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
  }

  .toolbar-left {
    color: #333;
    display: flex;
    flex-direction: column;
  }

  .toolbar-left h2 {
    font-size: 1.8rem;
    margin-bottom: 5px;
    font-weight: bold;
    color: #2c3e50;
  }

  .toolbar-left p {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #7f8c8d;
  }

  .weather-display {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .weather-info {
    color: #2980b9;
    display: flex;
    flex-direction: column;
  }

  .weather-item {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    color: #34495e;
    margin-bottom: 8px;
  }

  .weather-icon {
    margin-right: 10px;
    color: #2980b9;
    font-size: 24px;
  }

  .weather-text {
    margin: 0;
    font-size: 1rem;
    white-space: pre;
    color: #34495e;
  }

  .toolbar-right {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Sliding in button animation */
  .animated-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
    animation: slideInFromLeft 1s ease-in-out forwards;
  }

  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  /* Card grid layout */
  .card-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
    max-width: 600px;
    margin: auto;
    animation: fadeIn 1s ease-in-out;
  }

  /* Individual Cards */
  .card {
    background-color: #ffffff;
    color: #333;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    text-align: left;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    opacity: 0;
    transform: scale(0.9);
    animation: zoomIn 0.8s ease-in-out forwards;
  }

  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  .icon {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #2980b9;
  }

  .info h3 {
    margin: 10px 0;
    font-size: 1.4rem;
    font-weight: bold;
    color: #2c3e50;
  }

  .info p {
    font-size: 1rem;
    color: #7f8c8d;
  }

  /* Zoom in animation for cards */
  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  /* Fade in animation for toolbar */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .custom-toolbar {
      padding: 10px;
      width: 100%;
    }

    .toolbar-left h2 {
      font-size: 1.4rem;
    }

    .toolbar-left p {
      font-size: 1rem;
    }

    .weather-info {
      font-size: 0.9rem;
    }

    .weather-icon {
      font-size: 18px;
    }

    .weather-text {
      font-size: small;
    }
  }

  @media (max-width: 600px) {
    .card-grid {
      grid-template-columns: repeat(2, 1fr);
    }

    .card {
      font-size: 0.8rem;
      padding: 10px;
    }

    .icon {
      font-size: 1.8rem;
    }

    .info h3 {
      font-size: 1rem;
    }

    .info p {
      font-size: 0.8rem;
    }

    .toolbar-left p {
      font-size: smaller;
    }
  }
</style>
