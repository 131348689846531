<template>
  <div class="menu-container">
    <!-- Toolbar -->
    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title class="custom-toolbar-title">
        <!-- Align "Functions" title next to the logo -->
        {{ 'Menu' }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <div class="avatar-container">
      <!-- Avatar or Loading Spinner -->
      <v-avatar color="grey" size="150" v-if="profileLoaded">
        <v-img :src="userProfile.profileImage" cover></v-img>
      </v-avatar>
      <v-progress-circular :size="50" color="primary" v-if="!profileLoaded" indeterminate></v-progress-circular>

      <div v-if="profileLoaded" style="text-align: center">
        <h2 style="font-size: 1.5rem; font-weight: bold; color: #333; font-family: 'Material Design Icons'">
          {{ userProfile?.name ? userProfile?.name : userProfile?.email }}
        </h2>
        <!-- <p style="font-size: 1rem; color: #666; font-style: italic; font-family: 'Material Design Icons'">
          {{ userProfile.jobTitle }}
        </p> -->
        <v-chip class="ma-2" color="black" variant="tonal">
          {{ userProfile.jobTitle }}
        </v-chip>
      </div>
      <v-list-item v-if="!profileLoaded" class="text-black" title="Loading Profile..."></v-list-item>
    </div>

    <v-dialog v-model="dialog" max-width="400" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title style="text-align: center">
          {{ dialogType === 'roomControl' ? 'Room Control Options' : 'Admin Panel Options' }}
        </v-card-title>
        <v-card-text>
          <v-row style="display: flex; justify-content: space-around">
            <v-col v-for="(control, index) in dialogType === 'roomControl' ? roomControls : adminControls" :key="index"
              cols="auto" class="d-flex flex-column" style="justify-content: center; align-items: center">
              <v-card class="tile-card" max-width="120"
                @click="dialogType === 'roomControl' ? goToHardwareControl(control) : goToAdminControl(control)"
                :disabled="(dialogType === 'roomControl' && control.title === 'Elevators' && !enableElevatorControl) ||
                  (control.title === 'Access Control' && !enableAccessControl) ||
                  (control.title === 'Room Control' && !enableRomControl)
                  ">
                <v-card-item class="d-flex flex-column align-items-center" style="padding: 16px; text-align: center">
                  <v-icon :style="{ color: control.color, fontSize: '36px' }">{{ control.icon }}</v-icon>
                  <span style="font-size: 0.85rem; font-weight: bold; margin-top: 0.8rem">{{ control.title }}</span>
                </v-card-item>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Language Switcher Dialog -->
    <v-dialog v-model="showLanguageDialog" max-width="300px">
      <v-card>
        <v-card-title style="text-align: center">
          {{ 'Language Selection' }}
        </v-card-title>
        <v-card-text>
          <v-row style="display: flex; justify-content: space-around">
            <v-col cols="auto" class="d-flex flex-column" style="justify-content: center; align-items: center">
              <v-card class="tile-card" max-width="120" @click="setLanguage('en')" :disabled="selectedLanguage === 'en'"
                :class="{ selected: selectedLanguage === 'en' }">
                <v-card-item class="d-flex flex-column align-items-center" style="padding: 16px; text-align: center">
                  <v-img style="font-size: 36px" :src="usa_flag" alt="English Flag" class="flag-icon" />
                  <span style="font-size: 0.85rem; font-weight: bold; margin-top: 0.8rem">{{ 'English' }}</span>
                </v-card-item>
              </v-card>
            </v-col>

            <v-col cols="auto" class="d-flex flex-column" style="justify-content: center; align-items: center">
              <v-card class="tile-card" max-width="120" @click="setLanguage('de')" :disabled="selectedLanguage === 'de'"
                :class="{ selected: selectedLanguage === 'de' }">
                <v-card-item class="d-flex flex-column align-items-center" style="padding: 16px; text-align: center">
                  <v-img style="font-size: 36px" :src="german_flag" alt="German Flag" class="flag-icon" />
                  <span style="font-size: 0.85rem; font-weight: bold; margin-top: 0.8rem">{{ 'German' }}</span>
                </v-card-item>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn variant="tonal" small color="error" @click="cancelLanguageChange">{{ $t('menu.cancel') }}</v-btn>
          <v-btn variant="tonal" small color="success" class="white--text" @click="confirmLanguageChange">{{
            $t('menu.change') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Main Content -->
    <div class="cards-wrapper">
      <div class="cards-container">
        <div v-for="(item, index) in menuItems" :key="index" class="menu-card-col">
          <v-card class="menu-card" elevation="5" @click="goToMenuItem(item)">
            <v-card-item>
              <v-card-title>
                <div class="icon-wrapper" :style="{ background: item.background }">
                  <v-icon class="menu-icon" :style="{ color: item.color }">
                    {{ item.icon }}
                  </v-icon>
                </div>
              </v-card-title>
              <v-card-title class="card-title">
                {{ item.title }}
              </v-card-title>
              <v-card-subtitle class="card-subtitle">
                {{ item.subtitle }}
              </v-card-subtitle>
            </v-card-item>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, onMounted } from 'vue'
import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
import { useRouter } from 'vue-router'
import { isWebPlatform, setCookie, getCookie } from '@/services/utils'
import { useOAuthService } from '@/services/OAuthService'
import { doLogout } from '@/controllers/BackboneAPI'
import Swal from 'sweetalert2'
import user_avatar_3d from '@/assets/General/user_avatar_3d.png'
import { getFile, getUserProfiles, getDataFromBlob, getUserProfileById, getFeatures } from '@/controllers/BaseController'
import lodash from 'lodash'
import { useI18n } from 'vue-i18n'
import german_flag from '@/assets/General/german_flag.svg'
import usa_flag from '@/assets/General/usa_flag.svg'

export default defineComponent({
  name: 'MenuView',
  setup() {
    const { locale } = useI18n()
    const showLanguageDialog = ref(false)
    const selectedLanguage = ref('en')
    const initialLanguage = ref('en')
    const logo = ref('')
    const dialog = ref(false)
    const dialogType = ref('')
    const enableAccessControl = ref(true)
    const profileLoaded = ref(false)
    const enableElevatorControl = ref(true)
    const enableRomControl = ref(true)
    const roomControls = ref([
      { title: 'Access Control', icon: 'mdi-lock-outline' },
      { title: 'Room Control', icon: 'mdi-lightbulb-outline' },
      { title: 'Elevators', icon: 'mdi-elevator' },
    ])
    // const language = ref([
    //   { title: 'English', icon: 'mdi-message-outline' },
    //   { title: 'German', icon: 'mdi-calendar-check-outline'},
    // ])
    const adminControls = ref([
      { title: 'Feedbacks Management', icon: 'mdi-message-outline', color: 'green' },
      { title: 'Bookings Management', icon: 'mdi-calendar-check-outline', color: 'blue' },
      { title: 'Users Management', icon: 'mdi-account-supervisor', color: 'purple' },
      { title: 'Incidents Management', icon: 'mdi-alert-circle-outline', color: 'red' },
    ])

    const router = useRouter()
    const OAuthService = useOAuthService()
    const userProfile = ref(null)
    const goToMenuItem = async item => {
      switch (item.title) {
        case 'Profile':
          router.push({ name: 'UserProfile' }).catch(err => console.error(err))
          break
        case 'Room Control':
          dialogType.value = 'roomControl'
          dialog.value = true
          break
        case 'Analytics':
          router.push({ name: 'AnalyticsList' }).catch(err => console.error(err))
          break
        case 'Language':
          showLanguageDialog.value = true
          break
        case 'Admin Panel':
          dialogType.value = 'adminPanel'
          dialog.value = true
          break
        case 'Feedback':
          router.push({ name: 'FeedbackForm' }).catch(err => console.error(err))
          break
        case 'Incidents':
          router.push({ name: 'Incidents' }).catch(err => console.error(err))
          break
        case 'Vouchers':
          router.push({ name: 'Vouchers' }).catch(err => console.error(err))
          break
        case 'Slido':
          router.push({ name: 'SlidoView' }).catch(err => console.error(err));
          break;
        case 'Functions':
          router.push({ name: 'Functions' }).catch(err => console.error(err))
          break
        case 'Logout':
          await doLogout()
          break
        default:
          console.log('Unknown item clicked')
          break
      }
    }

    const setLanguage = lang => {
      selectedLanguage.value = lang
    }

    const confirmLanguageChange = async () => {
      showLanguageDialog.value = false
      Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to change the language?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#4caf50',
        cancelButtonColor: 'red',
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
      }).then(async result => {
        if (result.isConfirmed) {
          // User confirmed the language change
          locale.value = selectedLanguage.value
          await setCookie('language', selectedLanguage.value, 365) // Store language in cookie

          window.location.reload() // Reload the app
        } else {
          // User canceled, revert to original language
          selectedLanguage.value = initialLanguage.value
          showLanguageDialog.value = true
        }
      })
    }

    const cancelLanguageChange = () => {
      selectedLanguage.value = initialLanguage.value
      showLanguageDialog.value = false
    }
    const loadLanguageFromCookie = async () => {
      const savedLanguage = await getCookie('language')
      if (savedLanguage) {
        selectedLanguage.value = savedLanguage
        locale.value = savedLanguage // Apply saved language
      } else {
        await setCookie('language', 'en', 365) // Default to English if no cookie found
      }
    }

    // When dialog is opened, store the current language
    const openLanguageDialog = () => {
      initialLanguage.value = locale.value // Store the initial language
      selectedLanguage.value = locale.value
      showLanguageDialog.value = true
    }
    const goToHardwareControl = control => {
      console.log('Selected:', control.title)
      dialog.value = false
      switch (control.title) {
        case 'Access Control':
          router.push({ name: 'LocksView' }).catch(err => console.error(err))
          break
        case 'Room Control':
          router.push({ name: 'RoomControlPois' }).catch(err => console.error(err))
          break
        case 'Elevators':
          router.push({ name: 'Elevator' }).catch(err => console.error(err))
          break
        default:
          console.log('Unknown control clicked')
          break
      }
    }

    const goToAdminControl = control => {
      dialog.value = false
      switch (control.title) {
        case 'Feedbacks Management':
          router.push({ name: 'AdminFeedbacks' })
          break
        case 'Bookings Management':
          router.push({ name: 'BookingsManagement' })
          break
        case 'Users Management':
          router.push({ name: 'UsersManagement' })
          break
        case 'Incidents Management':
          router.push({ name: 'AdminIncidentsManagement' })
          break
        default:
          console.log('Unknown admin control clicked')
          break
      }
    }
    const menuItems = ref([
      {
        title: 'Profile',
        subtitle: 'Customize preferences and controls.',
        icon: 'mdi-account',
        color: 'white',
        background: '#9FC6A0',
      },
      {
        title: 'Room Control',
        subtitle: 'Manage lighting, climate, and devices.',
        icon: 'mdi-lightbulb-outline',
        color: 'white',
        background: '#F1C40F',
      },
      {
        title: 'Analytics',
        subtitle: 'Track and analyze data insights.',
        icon: 'mdi-chart-line',
        color: 'white',
        background: '#E67E22',
      },
      {
        title: 'Incidents',
        subtitle: 'Manage office/room incidents.',
        icon: 'mdi-alert-circle-outline',
        color: 'white',
        background: '#E74C3C',
      },
      {
        title: 'Language',
        subtitle: 'Set and manage language settings.',
        icon: 'mdi-translate',
        color: 'white',
        background: '#4A90E2',
      },
      {
        title: 'Admin Panel',
        subtitle: 'Access and manage admin settings.',
        icon: 'mdi-shield-account-outline',
        color: 'white',
        background: '#16A085',
      },
      {
        title: 'Slido',
        subtitle: 'Interact with live polls and questions.',
        icon: 'mdi-poll',
        color: 'white',
        background: '#2E86C1',
      },
      // {
      //   title: 'Booking Management',
      //   subtitle: 'Handle and organize bookings easily.',
      //   icon: 'mdi-calendar-check-outline',
      //   color: 'white',
      //   background: '#D691C1',
      // },
      {
        title: 'Vouchers',
        subtitle: 'Create, manage, and redeem vouchers.',
        icon: 'mdi-ticket-percent-outline',
        color: 'white',
        background: '#3498DB',
      },
      {
        title: 'Privacy',
        subtitle: 'Control and manage data privacy.',
        icon: 'mdi-lock-outline',
        color: 'white',
        background: '#2ECC71',
      },
      {
        title: 'Feedback',
        subtitle: 'Collect and review user feedback.',
        icon: 'mdi-message-outline',
        color: 'white',
        background: '#F39C12',
      },
      {
        title: 'Help',
        subtitle: 'Get assistance and support here.',
        icon: 'mdi-help-circle-outline',
        color: 'white',
        background: '#4A90E2',
      },
      {
        title: 'Functions',
        subtitle: 'The best work solution, With the best functions.',
        icon: 'mdi-rocket',
        color: 'white',
        background: '#101F52',
      },
      {
        title: 'Logout',
        subtitle: 'Sign out from your account safely.',
        icon: 'mdi-logout',
        color: 'white',
        background: '#9DA3A6',
      },
    ])

    async function loadUserProfileDetails() {
      try {
        const apiProfiles = await getUserProfiles()
        let apiProfile = lodash.find(apiProfiles, { email: userProfile.value.email })

        if (apiProfile) {
          apiProfile = await getUserProfileById(apiProfile.id)
          userProfile.value.jobTitle = apiProfile.job ? apiProfile.job : 'Employee'
          const imageId = lodash.get(apiProfile, 'files.entities', []).find(file => file.type === 'profileImage')?.id
          if (imageId) {
            const profileImageFile = await getFile(imageId)
            const profileImage = profileImageFile ? await getDataFromBlob(profileImageFile) : user_avatar_3d
            userProfile.value.profileImage = profileImage
          }
        }
      } catch (error) {
        console.error('Failed to load user profile details', error)
        profileLoaded.value = true
      } finally {
        profileLoaded.value = true
      }
    }

    const appendFeatures = async () => {
      try {
        let features = await getFeatures() // Assuming this returns the configuration object you provided

        let MENU_ITEMS = JSON.parse(JSON.stringify(menuItems.value)) // Clone the menu items
        let filteredMenuItems = []
        console.log(MENU_ITEMS)
        MENU_ITEMS.forEach(item => {
          const title = item.title.toLowerCase()

          // Handle menu items visibility based on enabled features
          switch (title) {
            case 'profile':
              if (features?.userOptions?.enabled && features?.userOptions?.profileUpdate) {
                filteredMenuItems.push(item)
              }
              break
            case 'room control':
              if (features?.space?.enabled && features?.space?.roomHardwares) {
                filteredMenuItems.push(item)
              }
              break
            case 'analytics':
              if (features?.analytics?.enabled) {
                filteredMenuItems.push(item)
              }
              break
            case 'incidents':
              if (features?.space?.enabled && features?.space?.inicidentCreation) {
                filteredMenuItems.push(item)
              }
              break
            case 'language':
              if (features?.appSettings?.enabled && features?.appSettings?.languageChange) {
                filteredMenuItems.push(item)
              }
              break
            case 'admin panel':
              if (features?.admin?.enabled) {
                adminControls.value = features?.admin?.feedbacksList
                  ? adminControls.value
                  : adminControls.value.filter(control => control.title !== 'Feedbacks')
                filteredMenuItems.push(item)
              }
              break
            case 'slido':
              console.log('Slido feature:', features?.userOptions?.slido)
              if (features?.userOptions?.enabled && features?.userOptions?.slido) {
                filteredMenuItems.push(item)
              }
              break
            case 'vouchers':
              if (features?.userOptions?.enabled && features?.userOptions?.vouchers) {
                filteredMenuItems.push(item)
              }
              break
            case 'privacy':
              if (features?.appSettings?.enabled && features?.appSettings?.privacyPolicy) {
                filteredMenuItems.push(item)
              }
              break
            case 'feedback':
              if (features?.userOptions?.enabled && features?.userOptions?.feedback) {
                filteredMenuItems.push(item)
              }
              break
            case 'help':
              if (features?.appSettings?.enabled && features?.appSettings?.helpSection) {
                filteredMenuItems.push(item)
              }
              break
            case 'functions':
              if (features?.functionsList && features?.functionsList?.enabled) {
                filteredMenuItems.push(item)
              }
              break
            case 'logout':
              filteredMenuItems.push(item)
              break
            default:
              console.log('No matching feature for', title)
          }
        })

        // Update the menuItems ref with the filtered items
        menuItems.value = filteredMenuItems
      } catch (error) {
        console.error('Failed to load user profile details', error)
      }
    }

    onMounted(async () => {
      try {
        await appendFeatures()
        const [logoImage, userProfileData, webPlatform] = await Promise.all([getToolbarLogoImage(), OAuthService.getUserProfile(), isWebPlatform()])
        logo.value = logoImage
        userProfile.value = userProfileData
        profileLoaded.value = false
        if (webPlatform) {
          enableAccessControl.value = false
        }
        loadUserProfileDetails()
        loadLanguageFromCookie()
      } catch (error) {
        console.error(error)
      }
    })

    return {
      menuItems,
      logo,
      userProfile,
      router,
      OAuthService,
      doLogout,
      goToMenuItem,
      user_avatar_3d,
      dialog,
      roomControls,
      goToHardwareControl,
      enableAccessControl,
      enableElevatorControl,
      enableRomControl,
      profileLoaded,
      dialogType,
      goToAdminControl,
      adminControls,
      showLanguageDialog,
      selectedLanguage,
      setLanguage,
      confirmLanguageChange,
      cancelLanguageChange,
      openLanguageDialog,
      german_flag,
      usa_flag,
    }
  },
})
</script>

<style scoped>
.selected {
  border: 2px solid #007bff;
  /* Highlight the selected language card */
  box-shadow: 0px 6px 16px rgba(0, 123, 255, 0.2);
}

.tile-card {
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  /* Elegant shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  /* Reduced padding */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  /* Reduced width */
}

.tile-card:hover {
  transform: translateY(-5px);
  /* Lift effect on hover */
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
  /* Stronger shadow on hover */
}

.tile-card v-icon {
  font-size: 32px;
  /* Slightly smaller icon size */
}

.fab-title {
  font-size: 0.85rem;
  font-weight: bold;
  text-align: center;
  margin-top: 8px;
  /* Reduced margin */
}

.fab-button {
  box-shadow: none;
  /* Remove extra shadow from buttons to keep the design simple */
}

.avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  text-align: center;
}

.avatar {
  margin-bottom: 0.5rem;
}

.avatar-name {
  font-size: 1.25rem;
  font-weight: bold;
}

@media (max-width: 600px) {
  .tile-card {
    max-width: 80px;
    /* Smaller tile for mobile screens */
    padding: 8px;
  }

  .avatar-container {
    margin: 1rem 0;
  }

  .avatar-name {
    font-size: 1rem;
  }
}

.custom-toolbar {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  color: #333;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.custom-toolbar-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #333;
  margin-left: 10px;
  /* Spacing between logo and title */
}

.toolbar-icon {
  height: 30px;
}

.menu-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding: 1rem;
}

.cards-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 16px;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  /* Reduced gap between cards */
}

.menu-card-col {
  flex: 1 1 calc(50% - 8px);
  /* 2 cards per row on small screens */
  max-width: calc(50% - 8px);
  box-sizing: border-box;
}

@media (min-width: 600px) {
  .menu-card-col {
    flex: 1 1 calc(33.33% - 8px);
    /* 3 cards per row on medium screens */
    max-width: calc(33.33% - 8px);
  }
}

@media (min-width: 960px) {
  .menu-card-col {
    flex: 1 1 calc(25% - 8px);
    /* 4 cards per row on larger screens */
    max-width: calc(25% - 8px);
  }

  .cards-wrapper {
    margin-bottom: 15rem;
  }
}

.menu-card {
  height: 120px;
  /* Fixed height for all cards */
  border-radius: 16px;
  transition: transform 0.3s ease;
  cursor: pointer;
  text-align: left;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  /* Softer shadow */
  background-color: #ffffff;
}

.menu-card:hover {
  transform: translateY(-5px);
}

.icon-wrapper {
  background-color: #f0f4f8;
  padding: 8px;
  border-radius: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.menu-icon {
  font-size: 30px;
}

.card-title,
.card-subtitle {
  white-space: break-spaces;
}

@media (min-width: 1200px) {
  .menu-card-col {
    flex: 1 1 calc(20% - 16px);
    /* Adjusted width for larger screens */
    max-width: 250px;
    /* Set a max-width to prevent cards from becoming too wide */
  }

  .menu-card {
    height: 140px;
  }

  .card-title {
    margin-top: 0.5rem;
    font-size: 18px;
    white-space: break-spaces;
  }

  .card-subtitle {
    font-size: 13px;
    white-space: break-spaces;
  }
}

@media (max-width: 780px) {
  .menu-card {
    height: 105px;
  }

  .card-title {
    font-size: 18px;
    white-space: break-spaces;
  }

  .card-subtitle {
    font-size: 13px;
    white-space: break-spaces;
  }

  .menu-icon {
    font-size: 24px;
  }

  .icon-wrapper {
    padding: 6px;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .menu-card {
    height: 140px;
  }

  .card-title {
    font-size: 16px;
    font-weight: bold;
    white-space: break-spaces;
  }

  .card-subtitle {
    font-size: 14px;
    white-space: break-spaces;
  }
}

@media (min-width: 800px) and (max-width: 1200px) {
  .menu-card {
    height: 110px;
  }

  .card-title {
    font-size: 16px;
    font-weight: bold;
    white-space: break-spaces;
  }

  .card-subtitle {
    font-size: 13px;
    white-space: break-spaces;
  }

  .menu-icon {
    font-size: 20px;
  }

  .icon-wrapper {
    padding: 4px;
  }
}

@media (min-width: 480px) and (max-width: 800px) {
  .menu-card {
    height: 105px;
  }

  .card-title {
    font-size: 14px;
    font-weight: bold;
    white-space: break-spaces;
  }

  .card-subtitle {
    font-size: 13px;
    white-space: break-spaces;
  }

  .menu-icon {
    font-size: 20px;
  }

  .icon-wrapper {
    padding: 4px;
  }
}

@media (max-width: 480px) {
  .menu-card {
    height: 105px;
  }

  .card-title {
    font-size: 12px;
    font-weight: bold;
    white-space: break-spaces;
  }

  .card-subtitle {
    font-size: 11px;
    white-space: break-spaces;
  }

  .menu-icon {
    font-size: 20px;
  }

  .icon-wrapper {
    padding: 4px;
  }
}
</style>
